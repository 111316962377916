import React from "react";
import Routes from "routes";
import CustomRouter from "components/CustomBrowserRouter";
import history from "components/history";
import ThemeProvider from "./theme";
import Toast from "components/Toast";
import { Provider } from "react-redux";
import store from "store/store";
import { FormProvider } from "components/FormContext";
import Loader from "components/Loader";
import axios from "axios";
import Cookie from "utils/cookie";
import Notification from 'components/PushNotification/Tost'
import { Helmet } from 'react-helmet-async';

const offset = new Date();

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
axios.defaults.headers.common["x-access-token"] = Cookie.get("jwt");
axios.defaults.headers.common["tz-offset"] = offset;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 404) {
      Cookie.delete("userId");
      Cookie.delete("jwt");
      Cookie.delete("userType");
      history.push("/login");
    }
    throw error;
  }
);

function App() {
  return (
    <>
      <Helmet>
        <title>FanatIQ Trivia</title>
        <meta property="og:title" content="FanatIQ Trivia" />
        <meta property="og:image" content="https://strikeout-trivia.blr1.digitaloceanspaces.com/26-03-2024-403591711469039296_Fanatiq-trivia-logo.png" />
        <meta name="description" content="Once daily sports trivia game covering current and historical events from all major sports. Test your knowledge, compete with friends, and improve your sports IQ." />
        <meta name="keywords" content="trivia, daily sport, major sports, events questions, seo, Once daily trivia game, questions,  historical bonus question " />
        <meta name="author" content="Christopher McCarroll-Gilbert" />
        <meta property="og:title" content="My Page Title" />
        <meta property="og:description" content="Once daily sports trivia game covering current and historical events from all major sports. Test your knowledge, compete with friends, and improve your sports IQ." />
        <meta property="og:url" content="https://strikeout-trivia.blr1.digitaloceanspaces.com/26-03-2024-403591711469039296_Fanatiq-trivia-logo.png" />
        <meta name="twitter:title" content="FanatIQ Trivia" />
        <meta name="twitter:description" content="Once daily sports trivia game covering current and historical events from all major sports. Test your knowledge, compete with friends, and improve your sports IQ." />
        <meta name="twitter:image" content="https://strikeout-trivia.blr1.digitaloceanspaces.com/26-03-2024-403591711469039296_Fanatiq-trivia-logo.png" />
      </Helmet>


      <Notification />
      <Provider store={store}>
        <ThemeProvider>
          <FormProvider>
            <React.Suspense fallback={<Loader />}>
              <CustomRouter history={history}>
                <Routes />
              </CustomRouter>
            </React.Suspense>
          </FormProvider>
          <Toast />
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;
