import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import Cookie from 'utils/cookie';
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyA93dMEFgZKLYOIP6E7dxE_d2TXOzOXQ38",
    authDomain: "fanatiq-aff98.firebaseapp.com",
    projectId: "fanatiq-aff98",
    storageBucket: "fanatiq-aff98.firebasestorage.app",
    messagingSenderId: "672500655466",
    appId: "1:672500655466:web:700ab299bef4f4c907f113",
    measurementId: "G-KMHJVXL1MV"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();
const analytics = getAnalytics();
const auth = getAuth();

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
    prompt: "select_account"
});

export const requestForToken = () => {
    return getToken(messaging, {
        vapidKey: `BK1edIpjPBoWGJ05ZQwxpDdoE33BD_d1rK-XSAwuiPqOHRTmdZv5KfgY_4oGi---OzUNIYPnMPZPcGybd8tjdrA`
    })
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                Cookie.save("ftToken", currentToken);
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

export const screenAnalytics = async screen => {
    logEvent(analytics, 'Screen', {
        screenName: screen,
        Platform: 'Web',
    });
};

export const dataAnalytics = (eventName, data) => {
    logEvent(analytics, eventName, {
        ...data
    });
};

export const signInWithGooglePopup = async () => {
    try {
        const result = await signInWithPopup(auth, googleProvider);
        const user = result.user;
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const idToken = credential.idToken;
        const data = {
            idToken: idToken,
            email: user.email,
            picture: user.photoURL,
            name: user.displayName,
            IsWeb: true,
            firebase: 'google',
            sub: 'google'
        };
        return data;
    } catch (error) {
        console.error('Error during sign-in');
    }
};

export const signInWithApple = async () => {
    const appleProvider = new OAuthProvider("apple.com");
    try {
        const result = await signInWithPopup(auth, appleProvider);
        const user = result.user;
        const credential = OAuthProvider.credentialFromResult(result);
        const idToken = credential.idToken;
        const data = {
            idToken: idToken,
            email: user.email,
            picture: user.photoURL,
            name: user.displayName,
            IsWeb: true,
            firebase: 'apple',
            sub: 'apple'
        };
        return data;
    } catch (error) {
        console.error('Error during sign-in', error);
    }
};
