import React from 'react';
import { Navigate, useRoutes } from "react-router-dom";
import AuthPage from 'screens/auth';
import Dashboard from "layout/commanLayout";
import LayoutContent from "layout/layoutContent";
import Cookie from "./utils/cookie";

const HomePage = React.lazy(() => import("screens/HomePage"));
const PrivacyPolicyPage = React.lazy(() => import("screens/PrivacyPolicyPage"));
const AppPrivacyPolicyPage = React.lazy(() => import("screens/AppPrivacyPolicyPage"));
const Results = React.lazy(() => import("screens/Results"));
const ProfilePictureScreen = React.lazy(() => import("screens/ProfilePictureScreen"));
const GamePageScreen = React.lazy(() => import("screens/GamePageScreen"));
const Admins = React.lazy(() => import("screens/Admin/Admins"));
const LeaderboardScreen = React.lazy(() => import("screens/LeaderboardScreen"));
const AddEditQuestionScreen = React.lazy(() => import("screens/AddEditQuestionScreen"));
const AttendedUserBase = React.lazy(() => import("screens/Admin/AttendedUserBase"));
const FrequentlyAskedQuestions = React.lazy(() => import("screens/FAQ"));
const ViewUser = React.lazy(() => import("screens/Admin/ViewUserTab"));
const GroupTable = React.lazy(() => import("screens/GroupTab/GroupTable"));
const CreateGroup = React.lazy(() => import("screens/GroupTab/CreateGroup"));
const GroupTab = React.lazy(() => import("screens/GroupTab/index"));
const ClosedInquiriesView = React.lazy(() => import("screens/Admin/ClosedInquiriesView"));
const UserBase = React.lazy(() => import("screens/Admin/UserBase"));
const AllQuestionsView = React.lazy(() => import("screens/Admin/AllQuestionsView"));
const ContactusPage = React.lazy(() => import("screens/ContactusPage"));


const Routes = () => {
    const routes = [
        {
            path: "/",
            element: <Dashboard />,
            children: [
                { path: "dashboard", element: <LayoutContent noPadding={Cookie.get("userType") === "User"} element={HomePage} /> },
                { path: "play-game", element: <LayoutContent element={GamePageScreen} /> },
                { path: "results", element: <LayoutContent element={Results} /> },
                { path: "user", element: <LayoutContent element={UserBase} /> },
                { path: "group", element: <LayoutContent element={GroupTable} /> },
                { path: "leaderboard", element: <LayoutContent element={LeaderboardScreen} /> },
                { path: "profile", element: <LayoutContent element={ProfilePictureScreen} /> },
                { path: "admins", element: <LayoutContent element={Admins} /> },
                { path: "question", element: <LayoutContent element={AddEditQuestionScreen} /> },
                { path: "question/all", element: <LayoutContent element={AllQuestionsView} /> },
                { path: "FAQs", element: <LayoutContent element={FrequentlyAskedQuestions} /> },
                { path: "question/:id/attended-users", element: <LayoutContent element={AttendedUserBase} /> },
                { path: "question/:id", element: <LayoutContent element={AddEditQuestionScreen} /> },
                { path: "user/:id", element: <LayoutContent element={ViewUser} /> },
                { path: "leaderboard/:id", element: <LayoutContent element={ViewUser} /> },
                { path: "group/:id", element: <LayoutContent element={GroupTab} /> },
                { path: "join-group/:id/:deeplink", element: <LayoutContent element={GroupTab} /> },
                { path: "group/:id/:userId", element: <LayoutContent element={ViewUser} /> },
                { path: "create-group", element: <LayoutContent element={CreateGroup} /> },
                { path: "update-group/:id", element: <LayoutContent element={CreateGroup} /> },
                { path: "/", element: Cookie.get("jwt") ? <Navigate to="/dashboard" /> : <Navigate to="/login" /> },
                { path: "*", element: Cookie.get("jwt") ? <Navigate to="/dashboard" /> : <Navigate to="/login" /> },
            ]
        },
        { path: "/signup", element: <AuthPage /> },
        { path: "/login", element: Cookie.get("jwt") ? <Navigate to="/dashboard" /> : <AuthPage /> },
        { path: "/forget-password", element: <AuthPage /> },
        { path: "/reset-password", element: <AuthPage /> },
        { path: "/privacy-policy", element: <PrivacyPolicyPage /> },
        { path: "/contact-us", element: <ContactusPage /> },
        { path: "/app-privacy-policy", element: <AppPrivacyPolicyPage /> },
        { path: "*", element: <Navigate to="/login" /> },
    ];
    return useRoutes(routes);
};

export default Routes;
