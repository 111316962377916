import React from "react";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isMobile } from "react-device-detect";

const Card = styled(Box)(() => ({
    background: "#193f54",
    border: "1px solid #FFFFFF",
    borderRadius: "8px",
    padding: "5px 17px",
    cursor: "pointer",
    userSelect: "none"
}));

const SwipperContainer = () => {
    const handleClick = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <Stack
            direction="column"
            alignItems="center"
            margin="auto"
            width="70%"
        >
            {!isMobile && (
                <Box
                    component="img"
                    sx={{
                        width: 350,
                        height: "100%",
                        objectFit: "contain",
                    }}
                    src="/assets/logo/Logo.svg"
                    alt="WorkingIcon"
                />
            )}
            <Stack
                direction="row"
                justifyContent="center"
                flexWrap={isMobile ? "nowrap" : "wrap"}
                gap={isMobile ? 2 : 4}
                mt={3}
            >
                <Card onClick={() => handleClick("https://apps.apple.com/us/app/fanatiq-trivia/id6670449170")} display="flex" alignItems="center">
                    <img src="/assets/logo/iosIcon.svg" alt="iosIcon" />
                    <Box ml={1}>
                        {!isMobile && <Typography color="common.white" variant="caption" fontWeight="fontWeightLight">
                            Download on the
                        </Typography>}
                        <Typography color="common.white" variant="subtitle2" fontWeight="fontWeightMedium">
                            App Store
                        </Typography>
                    </Box>
                </Card>
                <Card onClick={() => handleClick("https://play.google.com/store/apps/details?id=com.fanatiq.trivia")} display="flex" alignItems="center">
                    <img src="/assets/logo/android.svg" alt="android" />
                    <Box ml={1}>
                        {!isMobile && 
                            <Typography color="common.white" variant="caption" fontWeight="fontWeightLight">
                                Get it on
                            </Typography>
                        }
                        <Typography color="common.white" variant="subtitle2" fontWeight="fontWeightMedium">
                            Google Play
                        </Typography>
                    </Box>
                </Card>
            </Stack>
        </Stack>
    );
};

export default SwipperContainer;
