import React, { createContext, useRef } from 'react';

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
    const formikRef = useRef();

    const handleSubmit = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit();
        }
    };

    return (
        <FormContext.Provider value={{ formikRef, handleSubmit }}>
            {children}
        </FormContext.Provider>
    );
};