const adminConfig = [
    {
        title: "Question List",
        path: "/dashboard",
        icon: <img src="/assets/logo/home.svg" alt="home" />,
        references: [
            "/dashboard",
            "/question",
        ],
    },
    {
        title: "User",
        path: "/user",
        icon: <img src="/assets/logo/LookupLogo.svg" alt="LookupLogo" />,
        references: [
            "/user",
        ],
    },
    {
        title: "Admin",
        path: "/admins",
        icon: <img src="/assets/images/admin.svg" alt="AdminIcon" />,
        references: [
            "/admins",
        ],
    },
    {
        title: "Leaderboard",
        path: "/leaderboard",
        icon: <img src="/assets/logo/ImportIcon.svg" alt="ImportIcon" />,
        references: [
            "/leaderboard",
        ],
    },
    {
        title: "FAQ",
        path: "/faq",
        icon: <img src="/assets/logo/Support.svg" alt="FAQ" />, 
        references: [
            "/faq",
        ],
    },
];

const userConfig = [
    {
        title: "Play",
        path: "/dashboard",
        icon: <img src="/assets/logo/home.svg" alt="home" />,
        references: [
            "/dashboard",
            "/play-game",
            "/results"
        ],
    },
    {
        title: "Leaderboard",
        path: "/leaderboard",
        icon: <img src="/assets/logo/ImportIcon.svg" alt="ImportIcon" />,
        references: [
            "/leaderboard",
        ],
    },
    {
        title: "Group",
        path: "/group",
        icon: <img src="/assets/logo/groups.svg" alt="GroupIcon" />,
        references: [
            "/group",
        ],
    },
    {
        title: "FAQ",
        path: "/FAQs",
        icon: <img src="/assets/logo/Support.svg" alt="FAQ" />,
        references: [
            "/faq",
        ],
    },
];

export const routesConfig = {
    "User": userConfig,
    "Admin": adminConfig,
};
